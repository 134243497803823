/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';


/**
 * Required to support Web Animations `@angular/platform-browser/animations`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
import 'web-animations-js';  // Run `npm install --save web-animations-js`.



/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
(function(global) {
    var channels = [];
  
    function BroadcastChannel(channel) {
      var $this = this;
      channel = String(channel);
  
      var id = '$BroadcastChannel$' + channel + '$';
  
      channels[id] = channels[id] || [];
      channels[id].push(this);
  
      this._name = channel;
      this._id = id;
      this._closed = false;
      this._mc = new MessageChannel();
      this._mc.port1.start();
      this._mc.port2.start();
  
      global.addEventListener('storage', function(e) {
        if (e.storageArea !== global.localStorage) return;
        if (e.newValue === null) return;
        if (e.key.substring(0, id.length) !== id) return;
        var data = JSON.parse(e.newValue);
        $this._mc.port2.postMessage(data);
      });
    }
  
    BroadcastChannel.prototype = {
      // BroadcastChannel API
      get name() { return this._name; },
      postMessage: function(message) {
        var $this = this;
        if (this._closed) {
          var e = new Error();
          e.name = 'InvalidStateError';
          throw e;
        }
        var value = JSON.stringify(message);
  
        // Broadcast to other contexts via storage events...
        var key = this._id + String(Date.now()) + '$' + String(Math.random());
        global.localStorage.setItem(key, value);
        setTimeout(function() { global.localStorage.removeItem(key); }, 500);
  
        // Broadcast to current context via ports
        channels[this._id].forEach(function(bc) {
          if (bc === $this) return;
          bc._mc.port2.postMessage(JSON.parse(value));
        });
      },
      close: function() {
        if (this._closed) return;
        this._closed = true;
        this._mc.port1.close();
        this._mc.port2.close();
  
        var index = channels[this._id].indexOf(this);
        channels[this._id].splice(index, 1);
      },
  
      // EventTarget API
      get onmessage() { return this._mc.port1.onmessage; },
      set onmessage(value) { this._mc.port1.onmessage = value; },
      addEventListener: function(type, listener /*, useCapture*/) {
        return this._mc.port1.addEventListener.apply(this._mc.port1, arguments);
      },
      removeEventListener: function(type, listener /*, useCapture*/) {
        return this._mc.port1.removeEventListener.apply(this._mc.port1, arguments);
      },
      dispatchEvent: function(event) {
        return this._mc.port1.dispatchEvent.apply(this._mc.port1, arguments);
      }
    };
    // @ts-ignore
    global.BroadcastChannel = global.BroadcastChannel || BroadcastChannel;
  }(self));